import React from "react";
import _ from "lodash";
import AppContainer from "../AppContainer";
import Button from "../Button";
import Card from "../Card";
import {
  TextInput,
  Select,
  PhoneInput,
  EmailInput,
  FullNameInput,
} from "../Inputs";
import { navigateTo } from "../../utils/location";
import { getOS } from "../../utils/system";
import PhotoUploader from "../PhotoUploader";
import styles from "./index.module.scss";
import Compress from "compress.js";

export default class extends React.Component {
  state = {
    selection: null,
    freeText: null,
    guestName: null,
    guestPhoneNumber: null,
    guestEmail: null,
    innerChoices: [],
    finished: false,
    location: null,
    image: null,
    granted: false,
  };

  feedbackFlow =
    this.props.pageContext.business.id === paparomanosId
      ? feedbackFlowPapaRomanos 
      : this.props.pageContext.business.id === amicisId 
      ? feedbackFlowAmcis
      : this.props.pageContext.business.id === beyondJuiceId
      ? feedbackFlowBeyond
      : this.props.pageContext.business.id === kaisinId
      ? feedbackFlowKaisin : 
      (this.props.pageContext.business.id === bgoodId || this.props.pageContext.business.id === grainmakerId) 
      ? feedbackFlowBgood
      : getFeedbackFlow(
          _.get(
            this.props,
            "appStyles.feedbackLanguage",
            _.get(this.props, "appStyles.defaultLanguage"),
          ),
        );

  componentDidMount() {
    this.props.resetSendFeedback();
    if (window.isNativeApp && window.requestCameraPermissions) {
      _.defer(() =>
        requestCameraPermissionsNative(
          () => {
            this.setState({ granted: true });
          },
          () => {
            // alert('Please enable camera permissions in settings');
          },
        ),
      );
    } else {
      this.setState({ granted: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.user.sendFeedbackState.sent &&
      this.props.user.sendFeedbackState.sent
    ) {
      this.setState({ ...this.state, finished: true });
    }
  }

  textInput = null;
  locationInput = null;
  onchange = (selection) => {
    var selectedFeedback = _.find(
      this.feedbackFlow,
      (obj) => obj.name === selection,
    );
    this.setState({ selection: selectedFeedback, innerChoices: [] });
  };

  getLocationOptions = () => {
    const {
      pageContext: { branches },
      T,
    } = this.props;
    return _.concat(
      { label: T("Choose Location (Optional)"), value: "" },
      _.sortBy(
        _.map(_.filter(branches, "isDisplayed"), (location) => {
          return { label: location.name, value: location.name };
        }),
        "label",
      ),
    );
  };

  onChangeInnerSelection = (selectionName, val) => {
    this.setState({
      innerChoices: _.concat(
        _.filter(
          this.state.innerChoices,
          (choice) => choice.name !== selectionName,
        ),
        {
          name: selectionName,
          value: val,
        },
      ),
    });
  };

  onChangeLocation = (selectedLocation) => {
    this.setState({ location: selectedLocation });
  };

  isValidField = (field) => !this.state[errorkey(field)] && !!this.state[field];

  onInputError = (fieldName) => (error) =>
    this.setState({
      [`${fieldName}_ERROR`]: error,
    });

  onInputValid = (fieldName) => (value) => {
    this.setState({
      [`${fieldName}_ERROR`]: null,
      [fieldName]: value,
    });
  };

  registerInput = (fieldName) => (ref) => {
    this.fieldsRefs = {
      ...this.fieldsRefs,
      [fieldName]: ref,
    };
  };

  onSubmit = () => {
    const { user } = this.props;
    const fields = user.loggedIn ? [] : [GUEST_NAME, GUEST_EMAIL, GUEST_PHONE];

    Promise.all(
      _.map(
        fields,
        (field) =>
          new Promise((resolve) =>
            this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            }),
          ),
      ),
    ).then(() => {
      if (!_.every(fields, this.isValidField)) {
        this.setState({ errorComponent: CONTACT_DETAILS }, () => {
          // this.scrollToErrorComponent();
        });
        console.log("Invalid Form - contact details");
        return;
      }
      const os = getOS();
      const appVersion = window.appVersion ? window.appVersion : "";
      const systemVersion = window.systemVersion ? window.systemVersion : "";
      const deviceName = window.deviceName ? window.deviceName : "";
      const choices = _.join(
        _.map(
          this.state.innerChoices,
          (choice) => `${choice.name} : ${choice.value}`,
        ),
        "<br>",
      );
      const guestDetailsText = this.props.user.loggedIn
        ? ""
        : `Guest Details:<br/>Name: ${
            this.state[GUEST_NAME]
          }<br/>Phone Number: ${this.state[GUEST_PHONE]}<br/>Email: ${
            this.state[GUEST_EMAIL]
          }<br/>`;
      const feedback = `<br>OS : ${os} - ${systemVersion}<br/>App version: ${appVersion}<br/>Device: ${deviceName}<br/>${
        this.state.selection.name
      }<br>location: ${this.state.location}<br>${choices}<br>Message : ${
        this.textInput.inputElement.value
      }<br>${guestDetailsText}`;

      this.props.sendFeedback({
        htmlString: feedback,
        image: this.state.image
          ? this.state.image.replace(/data:image\/(.*);base64,/g, "")
          : null,
      });
    });
  };

  updateFreeText = (text) => {
    this.setState({ freeText: text });
  };

  uploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const compress = new Compress();
    reader.addEventListener(
      "load",
      () => {
        if (file) {
          compress.compress([file], { size: 1 }).then((data) => {
            this.setState({
              image: `${_.get(_.first(data), "prefix")}${_.get(
                _.first(data),
                "data",
              )}`,
            });
          });
        }
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    const {
      T,
      pageContext: {
        business: { id },
      },
      appStyles,
      user,
      locations,
      app: { keyboardOpen },
    } = this.props;

    const getInputPropsFor = (inputId, refKey = "refEl") => ({
      appStyles,
      [refKey]: this.registerInput(inputId),
      onValid: this.onInputValid(inputId),
      onError: this.onInputError(inputId),
      rtl: appStyles.rtl,
      T: this.props.T,
    });

    return this.state.finished ? (
      <AppContainer.Content appStyles={appStyles}>
        <Card appStyles={appStyles}>
          <Card.Header>
            <Card.Header.Title appStyles={appStyles}>
              {T("Thank you!")}
            </Card.Header.Title>
          </Card.Header>
          <Card.Content>{T("Your feedback has been sent")}</Card.Content>
        </Card>
        <AppContainer.Footer>
          <AppContainer.Footer.Button
            onClick={() => {
              this.props.resetSendFeedback();
              navigateTo("/");
            }}
            appStyles={appStyles}
          >
            {T("Back to Home")}
          </AppContainer.Footer.Button>
        </AppContainer.Footer>
      </AppContainer.Content>
    ) : (
      <AppContainer.Content
        appStyles={appStyles}
        classNames={styles.DesktopGutters}
      >
        <Card appStyles={appStyles}>
          <Card.Header>
            <Card.Header.Title appStyles={appStyles}>
              {T("What's on your mind?")}
            </Card.Header.Title>
          </Card.Header>
          <Card.Description appStyles={appStyles}>
            {T("{{feedback_disclamer}}").replace("{{feedback_disclamer}}", "")}
          </Card.Description>
          <Card.Content>
            <Card.Content.RadioGroup
              value={this.state.selection ? this.state.selection.name : null}
              onChange={this.onchange}
              appStyles={appStyles}
            >
              {_.map(this.feedbackFlow, (feedback) => (
                <Card.Content.RadioOption
                  iconSize={16}
                  iconInnerSize={16}
                  pointColor={appStyles.actionColor}
                  value={feedback.name}
                  key={feedback.name}
                >
                  <span>{feedback.name}</span>
                </Card.Content.RadioOption>
              ))}
            </Card.Content.RadioGroup>
          </Card.Content>
        </Card>
        {this.state.selection &&
          _.map(this.state.selection.values, (selectionVal) => (
            <Card appStyles={appStyles} key={selectionVal}>
              <Card.Header>
                <Card.Header.Title appStyles={appStyles}>
                  {selectionVal.name}
                </Card.Header.Title>
              </Card.Header>
              <Card.Content>
                <Card.Content.RadioGroup
                  value={
                    this.state.innerChoices &&
                    _.find(
                      this.state.innerChoices,
                      (choice) => choice.name === selectionVal.name,
                    )
                      ? _.find(
                          this.state.innerChoices,
                          (choice) => choice.name === selectionVal.name,
                        ).value
                      : null
                  }
                  onChange={(val) =>
                    this.onChangeInnerSelection(selectionVal.name, val)
                  }
                  appStyles={appStyles}
                >
                  {_.map(selectionVal.values, (feedback) => (
                    <Card.Content.RadioOption
                      iconSize={16}
                      iconInnerSize={16}
                      pointColor={appStyles.actionColor}
                      value={feedback}
                      key={feedback}
                    >
                      <span>{feedback}</span>
                    </Card.Content.RadioOption>
                  ))}
                </Card.Content.RadioGroup>
              </Card.Content>
            </Card>
          ))}
        {this.state.selection && (
          <Card appStyles={appStyles}>
            <Card.Header>
              <Card.Header.Title appStyles={appStyles}>
                {T("Location")}
              </Card.Header.Title>
            </Card.Header>
            <Card.Content>
              <Select
                options={this.getLocationOptions()}
                onChange={this.onChangeLocation}
                refEl={(el) => (this.locationInput = el)}
                inputValidColor={appStyles.inputValidColor}
                appStyles={appStyles}
              />
            </Card.Content>
          </Card>
        )}
        {this.state.selection && (
          <Card appStyles={appStyles}>
            <Card.Header>
              <Card.Header.Title appStyles={appStyles}>
                {T("Feedback Message")}
              </Card.Header.Title>
            </Card.Header>
            <Card.Content>
              <TextInput
                refEl={(el) => (this.textInput = el)}
                onValid={this.updateFreeText}
                noCheckmark
                appStyles={appStyles}
              />
            </Card.Content>
          </Card>
        )}
        {!user.loggedIn && this.state.selection && (
          <Card appStyles={appStyles}>
            <Card.Header>
              <Card.Header.Title appStyles={appStyles}>
                {T("Your Details")}
              </Card.Header.Title>
            </Card.Header>
            <Card.Content>
              {_.map(
                [
                  [GUEST_NAME, T("Name"), FullNameInput],
                  [GUEST_EMAIL, T("Email"), EmailInput],
                  [GUEST_PHONE, T("Phone Number"), PhoneInput],
                ],
                ([key, label, InputComponent]) => (
                  <div>
                    <label>{label}</label>
                    <InputComponent
                      {...getInputPropsFor(key)}
                      placeholder={label}
                    />
                  </div>
                ),
              )}
            </Card.Content>
          </Card>
        )}
        {this.state.selection && this.state.granted && (
          <Card appStyles={appStyles}>
            <Card.Header>
              <Card.Header.Title appStyles={appStyles}>
                {T("Screenshot")}
              </Card.Header.Title>
            </Card.Header>
            <Card.Content>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PhotoUploader
                  T={T}
                  appStyles={appStyles}
                  onChange={this.uploadImage}
                  image={this.state.image}
                />
                {this.state.image && (
                  <img
                    src={this.state.image}
                    style={{ width: 120, height: 100 }}
                  />
                )}
              </div>
            </Card.Content>
          </Card>
        )}
        {this.state.selection && (
          <Button
            centered
            style={{ margin: 16 }}
            appStyles={appStyles}
            onClick={this.onSubmit}
            loading={user.sendFeedbackState.sending}
            completed={user.sendFeedbackState.sent}
          >
            {T("Send Feedback")}
          </Button>
        )}
      </AppContainer.Content>
    );
  }
}

const GUEST_NAME = "guestName";
const GUEST_EMAIL = "guestEmail";
const GUEST_PHONE = "guestPhone";
const CONTACT_DETAILS = "cd";

const errorkey = (key) => `${key}_ERROR`;

const requestCameraPermissionsNative = (success, error) => {
  window.requestCameraPermissionsError = error;
  window.requestCameraPermissionsSuccess = success;
  window.postMessage(JSON.stringify({ type: "REQUEST_CAMERA_PERMISSIONS" }));
};

const paparomanosId = "5e40445b75eb020e2facddca";
const beyondJuiceId = "5aa64369d1870543ad42cf3e";
const kaisinId = "5f840218dd95aa0e4ef15b4d";
const bgoodId = "614c29b54f98ac0e3d11e2a8";
const grainmakerId = "5a27ce33e4b02998418e057d";
const amicisId = "630c75df0006563d7f11d47b";

const feedbackFlowHeb = [
  { name: "משוב על שירות" },
  { name: "משוב על מוצר" },
  { name: "משוב על חווית משתמש באפליקציה" },
];

const feedbackFlowDe = [
  {
    name: "Feedback zum Service",
    values: [
      {
        name: "Um was für ein Thema geht es?",
        values: ["Restauranterfahrungen", "App oder Online Bestellung"],
      },
      {
        name: "Feedback zum Service",
        values: [
          "Schnelligkeit des Services",
          "Fehlende Produkte oder Zutaten",
          "Beschwerde über einen Mitarbeiter",
          "Unfreundliches Personal",
          "Sauberkeit",
          "Sonstiges",
        ],
      },
    ],
  },
  {
    name: "Feedback zum Produkt",
    values: [
      {
        name: "Feedback zum Produkt",
        values: [
          "Geschmack",
          "Temperatur",
          "Zubereitung des Produkts",
          "Inhaltstsoffe",
          "Nicht richtig zubereitet",
          "Portionsgröße",
          "Preis",
          "Sonstiges",
        ],
      },
    ],
  },
  {
    name: "Feedback zur APP-Nutzung",
    values: [
      {
        name: "Feedback zur APP-Nutzung",
        values: [
          "Probleme bei der Bezahlung",
          "Probleme beim einloggen",
          "Gutscheine lassen sich nicht einlösen",
          "Meine Besuche im Restaurant werden nicht erfasst",
          "Gutscheinannahme im Restaurant",
          "Meine Gutscheine lassen sich nicht teilen",
          "Habe für das falsche Restaurant bestellt",
          "Generelle Anregung zur App-Nutzung",
        ],
      },
    ],
  },
  {
    name: "Feedback zur Nutzung der Online Bestellung",
    values: [
      {
        name: "Feedback zur Nutzung der Online Bestellung",
        values: [
          "Probleme bei der Bezahlung",
          "Probleme beim einloggen",
          "Gutscheine lassen sich nicht einlösen",
          "Meine Gutscheine lassen sich nicht teilen",
          "Habe für das falsche Restaurant bestellt",
          "Generelle Anregung zur App-Nutzung",
        ],
      },
    ],
  },
  {
    name: "Allgemeine Notfälle",
    values: [
      {
        name: "Allgemeine Notfälle",
        values: [
          "Fremdkörper in Lebensmittel",
          "Gesundheitliche Probleme",
          "Allergien",
          "Verletzungen",
        ],
      },
    ],
  },
  {
    name: "Anfragen/Informationen",
    values: [
      {
        name: "Anfragen/Informationen",
        values: [
          "Du möchtest uns einen Ort für ein Restaurant vorschlagen",
          "Franchise Möglichkeiten",
          "Events vorschlagen",
          "Spendenanfrage",
          "Generelle Anfragen",
          "Möchtest du ein Lieferant von uns werden?",
        ],
      },
    ],
  },
];

const feedbackFlowEng = [
  {
    name: "Order Experience",
    values: [
      {
        name: "Where did you order?",
        values: ["In-Store", "App or Web"],
      },
      {
        name: "Feedback",
        values: [
          "Ordering took too long",
          "Couldn't find items or ingredients",
          "Employee comments",
          "Sanitation",
          "Other",
        ],
      },
    ],
  },
  {
    name: "Food Quality",
    values: [
      {
        name: "Food Quality",
        values: ["Taste", "Temperature", "Serving", "Pricing", "Other"],
      },
    ],
  },
  {
    name: "App or Web",
    values: [
      {
        name: "App or Web",
        values: [
          "Issues with payments",
          "Account access",
          "Gift redemption",
          "Order to wrong location",
          "Other",
        ],
      },
    ],
  },
  {
    name: "Other",
    values: [{ name: "Other", values: ["General comments"] }],
  },
];

const feedbackFlowBgood = [
  {
    name: "Order Experience",
    values: [
      {
        name: "Where did you order?",
        values: ["In-Store", "App or Web"],
      },
      {
        name: "Feedback",
        values: [
"I had an amazing experience!",
          "Ordering took too long",
          "Couldn't find items or ingredients",
          "Employee comments",
          "Sanitation",
          "Other",
        ],
      },
    ],
  },
  {
    name: "Food Quality",
    values: [
      {
        name: "Food Quality",
        values: ["I loved your food!", "Taste", "Temperature", "Serving", "Pricing", "Other"],
      },
    ],
  },
  {
    name: "App or Web",
    values: [
      {
        name: "App or Web",
        values: [
          "I had an amazing experience!",
"Issues with payments",
          "Account access",
          "Gift redemption",
          "Order to wrong location",
          "Other",
        ],
      },
    ],
  },
  {
    name: "Other",
    values: [{ name: "Other", values: ["General comments"] }],
  },
];

const feedbackFlowBeyond = [
  {
    name: "Order Experience",
    values: [
      {
        name: "Where did you order?",
        values: ["In-Store", "App or Web"],
      },
      {
        name: "Feedback",
        values: [
          "Ordering took too long",
          "Couldn't find items or ingredients",
          "Employee comments",
          "Sanitation",
          "Other",
        ],
      }
    ],
  },
  {
    name: "Food Quality",
    values: [
      {
        name: "Food Quality",
        values: ["Taste", "Temperature", "Serving", "Pricing", "Other"],
      }
    ],
  },
  {
    name: "App or Web",
    values: [
      {
        name: "App or Web",
        values: [
          "Issues with payments",
          "Account access",
          "Gift redemption",
          "Order to wrong location",
          "Other",
        ],
      }
    ],
  },
  {
    name: "Other",
    values: [
      { name: "Other", values: ["General comments"] }
    ],
  },
];

const feedbackFlowPapaRomanos = [
  {
    name: "Order Experience",
    values: [
      {
        name: "Where did you order?",
        values: ["In-Store", "App or Web"],
      },
      {
        name: "Your Comments",
        values: [
          "Ordering took too long",
          "Couldn't find items or ingredients",
          "Employee comments",
          "Sanitation",
          "Other",
        ],
      },
    ],
  },
  {
    name: "Food Quality",
    values: [
      {
        name: "Food Quality",
        values: ["Taste", "Temperature", "Serving", "Pricing", "Other"],
      },
    ],
  },
  {
    name: "App or Web",
    values: [
      {
        name: "App or Web",
        values: [
          "Issues with payments",
          "Account access",
          "Gift redemption",
          "Order to wrong location",
          "Other",
        ],
      },
    ],
  },
  {
    name: "Other",
    values: [{ name: "Other", values: ["General comments"] }],
  },
];

const feedbackFlowKaisin = [
  {
    name: "feedback zu station",
    values: [
      {
        name: "wie war dein allgemeines erlebnis?",
        values: ["1 (schlecht)", "2", "3", "4", "5 (sehr gut)"],
      },
      {
        name: "ist die app verständlich?",
        values: ["1 (schlecht)", "2", "3", "4", "5 (sehr gut)"],
      },
      {
        name: "war die lieferung immer pünktlich?",
        values: ["Ja", "nein"],
      },
      {
        name: "wie oft würdest du in zukunft über station bestellen?",
        values: [
          "nicht meh",
          "einmal pro monat",
          "einmal pro woche",
          "mehrmals pro woche",
        ],
      },
      {
        name: "hast du oder würdest du den isolierbag gerne behalten?",
        values: ["ja!", "nein"],
      },
    ],
  },
];

const feedbackFlowAmcis = [
  {
    name: "Order Experience",
    values: [
      {
        name: "Where did you order?", 
        values: [
          "In-Store",
          "App or Web"
        ]
      },
      {
        name: "Ordering Experience",
        values: [
          "Ordering took too long",
          "Process difficult",
          "Couldn't find items or ingredients",
          "Credit card issues",
          "Other"
        ],
        
      },
      {
        name: "About My Order",
        values: [
          "Timeliness",
          "Service",
          "Complete or something missing?"
        ],
        
      }],
      
    },
    {
      name: "Food Quality",
      values: [
        {
          name: "Food",
          values: [
            "Taste",
            "Temperature",
            "Serving",
            "Pricing",
            "Other"
          ],
          
        },
        
      ],
      
    },
    {
      name: "App or Web",
      values: [
        {
          name: "App or Web",
          values: [
            "Issues with payments",
            "Account access",
            "Gift redemption",
            "Order to wrong location",
            "Other"
          ],
          
        },
        
      ],
      
    },
    {
      name: "Other",
      values: [
        {
          name: "Other",
          values: [
            "General comments"
          ]
        }
      ]
    }
];

const getFeedbackFlow = (language) => {
  switch (language) {
    case "he":
      return feedbackFlowHeb;
    case "de":
      return feedbackFlowDe;
    case "en":
    default:
      return feedbackFlowEng;
  }
};
